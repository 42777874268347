 
<template>
  <div>
    <CustomDialog
      :open.sync="open"
      :title="dialog_title"
      @click-close="close_dialog"
      :max-width="mdAndDown ? `100%` : 900"
      ref="dialog"
      :has-button2="false"
      :has-button1="!mobilemonkey_setup"
      @button1="close_dialog"
      button1-text="Close"
    >
      <template v-slot:left-buttons>
        <span class="ml-2 caption">{{ hooks.length }}/{{ max_limit }}</span>
        <span v-show="reach_max" style="color: red" class="ml-3">
          Max webhooks limit reached!
        </span>
      </template>
      <template v-slot:extras>
        <v-btn
          color="primary"
          depressed
          class="text-none"
          @click="mobilemonkey_setup = !mobilemonkey_setup"
        >
          {{ mobilemonkey_setup ? "Back" : "MobileMonkey Setup" }}
        </v-btn>
      </template>
      <template v-slot:content>
        <v-row dense v-show="!mobilemonkey_setup">
          <v-col md="12">
            <v-card flat min-height="400">
              <v-card-text class="py-0">
                <v-form v-model="valid" class="mb-2">
                  <v-row dense>
                    <v-col :cols="mdAndUp ? 8 : 12">
                      <label class="subtitle-2 primary--text"> Name * </label>
                      <v-text-field
                        :rules="requiredRules"
                        outlined
                        dense
                        placeholder="Required"
                        hide-details="auto"
                        :disabled="reach_max"
                        v-model="hook.label"
                      ></v-text-field>
                    </v-col>
                    <v-col :cols="mdAndUp ? 4 : 12">
                      <label class="subtitle-2 primary--text">
                        Select API *
                      </label>
                      <v-select
                        :rules="requiredRules"
                        :items="types"
                        dense
                        item-value="value"
                        item-text="text"
                        v-model="type"
                        outlined
                        :disabled="reach_max"
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col :cols="mdAndUp ? 8 : 12">
                      <label class="subtitle-2 primary--text">
                        Assign generated leads to group
                      </label>
                      <v-select
                        dense
                        item-value="id"
                        item-text="name"
                        v-model="hook.groups"
                        :disabled="reach_max"
                        :items="lead_groups"
                        multiple
                        deletable-chips
                        chips
                        :rules="requiredRules"
                        small-chips
                        hide-details="auto"
                        outlined
                        placeholder="Select or create groups"
                      >
                        <template v-slot:append-outer>
                          <v-menu bottom left :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-text>
                                <label class="subtitle-2 primary--text">
                                  Group name
                                </label>
                                <v-text-field
                                  v-model="new_group"
                                  hide-details="auto"
                                  append-outer-icon="mdi-content-save-move-outline"
                                  outlined
                                  :rules="requiredRules"
                                  dense
                                  @click:append-outer="saveNewGroup"
                                >
                                </v-text-field>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col :cols="mdAndUp ? 4 : 12">
                      <label class="subtitle-2 primary--text">
                        Generate URL
                      </label>
                      <v-btn
                        block
                        color="primary"
                        @click="save"
                        :disabled="!valid || reach_max"
                        class="text-none"
                      >
                        Generate URL
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-form>
                <template v-if="hooks.length">
                  <p class="subtitle-2 primary--text">
                    Leads will be generated from the following webhooks:
                  </p>
                  <v-row
                    dense
                    class="border-1 mb-1 border-color--grey rounded-10 mt-1"
                    v-for="hook in hooks"
                    :key="hook.id"
                  >
                    <v-col cols="9">
                      <span class="d-block primary--text subtitle-2 pl-2">{{
                        hook.label | ucwords
                      }}</span>
                      <span class="d-block caption pl-2">{{ hook.url }}</span>
                    </v-col>
                    <v-spacer />
                    <v-col cols="2" class="pa-3">
                      <v-btn
                        @click="copyHook(hook)"
                        outlined
                        class="text-none mx-1"
                        icon
                        title="Copy URL"
                      >
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                      <v-btn
                        @click="confirmDelete(hook)"
                        outlined
                        class="text-none mx-1"
                        icon
                        title="Delete Webhook"
                      >
                        <v-icon small color="error">mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <Empty v-else headline="No webhooks yet."></Empty>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card min-height="400" flat v-show="mobilemonkey_setup">
          <v-card-text>
            <h2>Step 1 : Generate Webhook URL</h2>
            <div class="py-2">
              Generate a webhook URL for MobileMonkey
              <a class="text-none" @click="mobilemonkey_setup = false">here</a>
            </div>
            <v-divider class="my-2"></v-divider>
            <h2>Step 2 : Setup Webhook on MobileMonkey</h2>
            <div class="py-2">
              2.1. Login to your MobileMonkey Account and go to
              <b>Integration</b> > <b>JSON Webhooks</b> configuration page. (You
              may need some MobileMonkey Unicorn subscription for this)<br />
              <v-img
                max-width="700"
                class="my-1"
                :src="require('@/assets/tutorials/mobilemonkey-webhook.png')"
              ></v-img>
              2.2. Click <b>Add a connection</b> button (a popup will show about
              using Zapier, click <b>No, I'm not</b>) <br />
              2.3. Set Title and change status to <b>On</b>
              <v-img
                max-width="700"
                class="my-1"
                :src="require('@/assets/tutorials/mobilemonkey-webhook-2.png')"
              ></v-img>
              2.3. For connection type, select
              <b>Send contact data whenever a widget is reached</b><br />
              2.4. For request type, select <b>POST</b> and paste the generated
              URL to URL field <br />
              <v-img
                max-width="700"
                class="my-1"
                :src="require('@/assets/tutorials/mobilemonkey-webhook-3.png')"
              ></v-img>
              2.5. Set attributes to be sent to {{ settings.SiteName }}. See
              below image for example
              <v-img
                max-width="700"
                class="my-1"
                :src="require('@/assets/tutorials/mobilemonkey-webhook-4.png')"
              ></v-img>
            </div>
            <v-divider class="my-2"></v-divider>
            <h2>Step 3 : Use the connection in your dialogues</h2>
            <div class="py-2">
              3.1. In your dialogue add a widget.
              <v-img
                max-width="700"
                class="my-1"
                :src="
                  require('@/assets/tutorials/mobilemonkey-webhook-step-1.png')
                "
              ></v-img>
              3.2. Select More
              <v-img
                max-width="700"
                class="my-1"
                :src="
                  require('@/assets/tutorials/mobilemonkey-webhook-step-2.png')
                "
              ></v-img>
              3.3. From connection widget, select Zap/Connection
              <v-img
                max-width="700"
                class="my-1"
                :src="
                  require('@/assets/tutorials/mobilemonkey-webhook-step-3.png')
                "
              ></v-img>
              3.4. Select you connection from Step 2
              <v-img
                max-width="700"
                class="my-1"
                :src="
                  require('@/assets/tutorials/mobilemonkey-webhook-step-4.png')
                "
              ></v-img>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </CustomDialog>
    <DeleteDialog
      title="Confirmation Required"
      text-content="Are you sure you want to delete this webhook url? This cannot be undone."
      :open-dialog.sync="confirm_delete"
      @delete="deleteHook"
    ></DeleteDialog>
  </div>
</template>

<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
import request from "@/services/axios_instance";
import DeleteDialog from "@/common/DeleteDialog.vue";
import copy from "copy-to-clipboard";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "ImportViaWebhooks",
  components: { DeleteDialog, CustomDialog },
  props: {
    dialog: { type: Boolean, default: false },
    for: { type: String, default: "MobileMonkey" },
    groups: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    mobilemonkey_setup: false,
    confirm_delete: false,
    open: false,
    valid: false,
    hooks: [],
    hook: {
      label: null,
      groups: [],
    },
    types: [{ value: "mobilemonkey", text: "MobileMonkey" }],
    type: "mobilemonkey",
    activeHook: null,
    max_limit: 10,
    lead_groups: [],
    new_group: null,
  }),
  watch: {
    for(val) {
      if (val) this.type = val.toLowerCase();
    },
    dialog(val) {
      this.open = val;
      if (val) this.getHooks();
    },
    open(val) {
      this.$emit("update:dialog", val);
    },
    groups: {
      handler: function (val) {
        this.lead_groups = _cloneDeep(val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    reach_max() {
      return this.hooks.length >= this.max_limit;
    },
    dialog_title() {
      return `Webhooks Configuration (${this.for})`;
    },
  },
  methods: {
    close_dialog() {
      this.open = false;
    },
    getHooks() {
      request.get(`api/hooks/all/all`).then(({ data }) => {
        this.hooks = data;
      });
    },
    save() {
      request.post(`api/hooks/${this.type}`, this.hook).then(({ data }) => {
        this.hooks.push(data);
        this.hook.label = null;
        this.hook.groups = [];
        this.valid = false;
        this.appSnackbar("Webhook created.");
      });
    },
    copyHook(hook) {
      if (copy(hook.url)) {
        this.appSnackbar("Webhook url copied!");
      } else {
        this.appSnackbar(
          "Copy to clipboard not supported on this browser!",
          "error"
        );
      }
    },
    confirmDelete(hook) {
      this.activeHook = hook;
      this.confirm_delete = true;
    },
    deleteHook() {
      request
        .delete(`api/hooks/${this.activeHook.type}/${this.activeHook.code}`)
        .then(({ data }) => {
          let index = this.hooks.findIndex((i) => i.id === data.id);
          if (~index) {
            this.hooks.splice(index, 1);
            this.appSnackbar("Webhook deleted.");
            this.activeHook = null;
          }
        })
        .finally(() => {
          this.confirm_delete = false;
        });
    },
    saveNewGroup() {
      if (!this.new_group || this.new_group.trim().length < 1) {
        this.appSnackbar("Group name is required!", "error");
      } else {
        request
          .post(`api/leads/groups`, { name: this.new_group })
          .then(({ data }) => {
            this.new_group = null;
            this.lead_groups.unshift(data);
            this.$emit("new-group", data);
            this.appSnackbar("Group added!", "success");
          });
      }
    },
  },
};
</script>

<style scoped>
</style>
 