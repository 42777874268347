<template>
  <div class="leads">
    <VueTable
      :items="items"
      :headers="headers"
      :show-row-actions="true"
      @load-more="getMoreLeads"
      @selected="target_recipients = $event"
      no-row-delete
      icon="mdi-alpha-l-box-outline"
      title="Leads"
      min-height="80vh"
      :key="componentKey"
      :no-more-data="!has_more_items"
      empty-text="No leads yet"
      :show-select="true"
      :loading="loading_items"
    >
      <template v-slot:header-toolbar>
        <v-row dense class="fullwidth" align="center" justify="end">
          <v-text-field
            clearable
            clear-icon="mdi-close-circle-outline"
            placeholder="Search..."
            v-model="filters.search"
            hide-details
            outlined
            dense
            color="primary"
            style="max-width: 200px"
            class="primary--text ma-1"
            prepend-inner-icon="mdi-account-search-outline"
            @click:clear="filters.search = ''"
          ></v-text-field>
          <v-menu offset-overflow offset-y bottom>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    depressed
                    class="text-none ma-1"
                    color="primary"
                    height="40"
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                    <span>Add</span>
                    <v-spacer />
                    <v-icon right>mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Import or create leads</span>
              </v-tooltip>
            </template>
            <v-list dense>
              <template v-for="(item, index) in imports_from">
                <v-list-item :disabled="true" :key="index" v-if="!item.enabled">
                  <v-list-item-icon class="mr-2">
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="primary--text fw-700">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="false"
                  v-else
                  :key="index"
                  @click="openImport(item.value)"
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="primary--text fw-700">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <v-menu offset-overflow offset-y bottom>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    class="text-none primary--text ml-1 fw-900"
                    color="primary"
                    :height="40"
                    outlined
                    depressed
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                    <span>Status: {{ filters.status | ucwords }}</span>
                    <v-spacer />
                    <v-icon right>mdi-menu-swap</v-icon>
                  </v-btn>
                </template>
                <span>Filter by status</span>
              </v-tooltip>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in lead_statuses"
                :key="index"
                @click="filters.status = item.value"
              >
                <v-list-item-title class="text-left primary--text">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-overflow offset-y bottom>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    class="text-none primary--text ma-1 fw-900"
                    color="primary"
                    height="40"
                    outlined
                    depressed
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                    <span>
                      Group:
                      {{ filters.group.name | ucwords | truncate(12) }}
                    </span>
                    <v-spacer />
                    <v-icon right>mdi-menu-swap</v-icon>
                  </v-btn>
                </template>
                <span>Filter by group</span>
              </v-tooltip>
            </template>
            <v-list dense>
              <v-list-item @click="filters.group = { id: 'all', name: 'All' }">
                <v-list-item-title class="text-left primary--text fw-500">
                  All
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="(item, index) in groups"
                :key="index"
                @click="filters.group = item"
              >
                <v-list-item-title class="text-left primary--text fw-500">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>
      <template v-slot:header>
        <v-sheet class="mb-2">
          <v-row dense align="stretch" justify="center">
            <v-col :cols="mdAndUp ? 6 : 12">
              <apexchart
                height="160"
                type="line"
                class="border-1 border-color--grey rounded-10"
                :options="options"
                :series="series"
              ></apexchart>
            </v-col>
            <v-sheet class="col ma-1 pa-3 rounded-10" outlined>
              <h5 class="text-center fullwidth primary--text fs-16 fw-700">
                Leads
              </h5>
              <h4 class="subtitle-2 mb-1 primary--text">
                Total:
                <span class="float-right">
                  {{ analytics.leads.total || 0 }}
                </span>
              </h4>
              <h4 class="subtitle-2 mb-1 primary--text">
                Active:
                <span class="float-right">
                  {{ analytics.leads.active || 0 }}
                </span>
              </h4>
              <h4 class="subtitle-2 mb-1 primary--text">
                Inactive:
                <span class="float-right">
                  {{ analytics.leads.inactive || 0 }}
                </span>
              </h4>
            </v-sheet>
            <v-sheet class="col ma-1 pa-3 rounded-10" outlined>
              <h5 class="text-center fullwidth primary--text fs-16 fw-700">
                Groups
              </h5>
              <h4 class="subtitle-2 mb-1 primary--text">
                Total:
                <span class="float-right">
                  {{ analytics.groups.total || 0 }}
                </span>
              </h4>
              <h4 class="subtitle-2 mb-1 primary--text">
                Active:
                <span class="float-right">
                  {{ analytics.groups.active || 0 }}
                </span>
              </h4>
              <h4 class="subtitle-2 mb-1 primary--text">
                Inactive:
                <span class="float-right">
                  {{ analytics.groups.inactive || 0 }}
                </span>
              </h4>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="$router.push({ name: 'lead_groups' })"
                    color="primary"
                    class="text-none mt-2"
                    height="40"
                    depressed
                    block
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-account-group-outline</v-icon>
                    <span>Manage Groups</span>
                  </v-btn>
                </template>
                <span>Manage groups</span>
              </v-tooltip>
            </v-sheet>
          </v-row>
        </v-sheet>
      </template>
      <template v-slot:row-slot="{ item }">
        <td :id="`lead-${item.id}`">{{ item.fullname || '-' | ucwords }}</td>
        <td>{{ item.email }}</td>
        <td>
          {{ phoneFormat(item.contacts.e164 || item.contacts.input || '') }}
        </td>
        <td>{{ concatedGroups(item) }}</td>
        <td>{{ item.interests | trunc_mid(20, 10) }}</td>
        <td class="text-center">{{ item.status | ucwords }}</td>
        <td class="text-center">{{ item.created_at | format('lll') }}</td>
        <ActionDropdown
          :item="item"
          :has-edit="can_edit"
          :has-delete="can_delete"
          :has-view="false"
          @delete="deleteLead(item)"
          @edit="editLead(item)"
        ></ActionDropdown>
      </template>
      <template v-slot:extra-bulk-buttons>
        <v-menu offset-y top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              depressed
              v-bind="attrs"
              v-on="on"
              class="text-none"
              :min-width="180"
              :disabled="target_recipients.length < 1"
            >
              Select Action
              <v-spacer />
              <v-icon right>mdi-menu-swap</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="sendEmailCampaign">
              <v-list-item-title>Send Email Campaign</v-list-item-title>
            </v-list-item>
            <v-list-item @click="sendSmsCampaign">
              <v-list-item-title>Send SMS/MMS Campaign</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </VueTable>
    <ImportExcel
      :groups="groups"
      :dialog.sync="open_import_via_excel"
      @save="handleImportExcelSave"
      @new-group="appendGroup"
    ></ImportExcel>
    <ImportClients
      :groups="groups"
      :dialog.sync="open_import_via_clients"
      @save="handleImportClientsSave"
      @new-group="appendGroup"
    ></ImportClients>
    <LeadDialog
      :groups="groups"
      :item="activeLead"
      :dialog.sync="open_lead_dialog"
      @save="handleSaveLead"
      @update="handleUpdateLead"
      @new-group="appendGroup"
    ></LeadDialog>
    <LeadGroupDialog
      v-model="open_lead_group_dialog"
      @save="saveGroup"
    ></LeadGroupDialog>
    <DeleteDialog
      :open-dialog.sync="delete_dialog"
      title="Confirmation required"
      text-content="Delete this lead? This cannot be undone."
      delete-button-text="Yes! Delete"
      @delete="handleDeleteLead"
    ></DeleteDialog>
    <ImportViaWebhooks
      :dialog.sync="open_connect_with_webhooks"
      :groups="groups"
      for="MobileMonkey"
      @new-group="appendGroup"
    ></ImportViaWebhooks>
    <EmailCampaignCreator
      :templates="templates"
      :signatures="signatures"
      :targets="target_recipients"
      target-type="leads"
      :dialog.sync="send_email_campaign"
      @save="handleSaveEmailCampaign"
    ></EmailCampaignCreator>
    <SMSCampaignCreator
      :dialog.sync="send_sms_campaign"
      target-type="leads"
      :targets="target_recipients"
      @save="handleSaveSMSCampaign"
    ></SMSCampaignCreator>
  </div>
</template>

<script>
import { fetch_services } from '@/services/fetch/fetch_services'
import VueTable from '@/common/VueTable/VueTable.vue'
import ActionDropdown from '@/common/VueTable/ActionDropdown.vue'
import ImportExcel from '@/modules/Leads/components/ImportExcel.vue'
import ImportClients from '@/modules/Leads/components/ImportClients.vue'
import LeadDialog from '@/modules/Leads/components/LeadDialog.vue'
import LeadGroupDialog from '@/modules/Leads/components/LeadGroupDialog.vue'
import request from '@/services/axios_instance'
import _map from 'lodash/map'
import _reverse from 'lodash/reverse'
import _debounce from 'lodash/debounce'
import DeleteDialog from '@/common/DeleteDialog'
import { lead_mixins } from '@/modules/Leads/lead_mixins'
import { email_campaign_mixins } from '@/modules/Campaign/email_campaign_mixins'
import ImportViaWebhooks from '@/modules/Leads/components/ImportViaWebhooks.vue'
import EmailCampaignCreator from '@/modules/Campaign/components/EmailCampaignCreator.vue'
import SMSCampaignCreator from '@/modules/Campaign/components/SMSCampaignCreator.vue'
import phoneFormatter from 'phone-formatter'

export default {
  name: 'Leads',
  mixins: [fetch_services, lead_mixins, email_campaign_mixins],
  components: {
    ImportViaWebhooks,
    DeleteDialog,
    LeadDialog,
    ImportClients,
    ImportExcel,
    ActionDropdown,
    VueTable,
    EmailCampaignCreator,
    SMSCampaignCreator,
    LeadGroupDialog
  },
  data: () => ({
    send_email_campaign: false,
    send_sms_campaign: false,
    target_recipients: [],
    componentKey: 0,
    headers: [
      {
        text: 'Name',
        value: 'fullname',
        sortable: true,
        align: 'left',
        width: 150
      },
      {
        text: 'Email',
        value: 'email',
        sortable: true,
        align: 'left',
        width: 100
      },
      {
        text: 'Contact',
        value: 'contact_number.input',
        sortable: false,
        align: 'left',
        width: 150
      },
      { text: 'Groups', sortable: false, align: 'left', width: 120 },
      { text: 'Interests', value: 'interests', sortable: false, align: 'left' },
      {
        text: 'Status',
        value: 'status',
        sortable: true,
        align: 'center',
        width: 98
      },
      {
        text: 'Created At',
        value: 'created_at',
        sortable: true,
        align: 'center',
        width: 120
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'center',
        width: '40px'
      }
    ],
    filters: {
      search: '',
      status: 'all',
      group: { id: 'all', name: 'All' }
    },
    lead_statuses: [
      { text: 'All', value: 'all' },
      { text: 'Active', value: 'active' },
      { text: 'Inactive', value: 'inactive' }
    ],
    paths: [
      {
        text: 'Dashboard',
        disabled: false,
        route: { name: 'default-content' }
      },
      { text: 'Leads', disabled: true, route: null }
    ],
    imports_from: [
      {
        text: 'Create New Lead',
        value: 'create',
        enabled: true,
        icon: 'mdi-account-plus-outline'
      },
      {
        text: 'Create New Lead Group',
        value: 'create_group',
        enabled: true,
        icon: 'mdi-account-group'
      },
      {
        text: 'Import From Excel',
        value: 'excel',
        enabled: true,
        icon: 'mdi-file-import-outline'
      },
      {
        text: 'Import From Clients',
        value: 'clients',
        enabled: true,
        icon: 'mdi-target-account'
      },
      {
        text: 'Connect to MobileMonkey',
        value: 'webhooks',
        enabled: true,
        icon: 'mdi-cast-connected'
      }
    ],
    open_import_via_excel: false,
    open_import_via_clients: false,
    open_connect_with_webhooks: false,
    analytics: {
      graphs: [],
      leads: [],
      groups: []
    }
  }),
  mounted() {
    this.$event.$emit('path-change', this.paths)
    if (
      this.$route.query.hasOwnProperty('mobilemonkey') &&
      this.$route.query.mobilemonkey
    ) {
      this.open_connect_with_webhooks = true
    }
  },
  created() {
    this.getLeads()
    this.getGroups()
    this.getAnalytics()
  },
  watch: {
    'filters.search': {
      handler: function (val) {
        this.getLeads()
      },
      immediate: true
    },
    'filters.status': {
      handler: function (val) {
        this.getLeads()
      },
      immediate: true
    },
    'filters.group': {
      handler: function (val) {
        this.getLeads()
      },
      immediate: true
    }
  },
  computed: {
    analytics_values() {
      return _reverse(_map(this.analytics.graph, 'count'))
    },
    analytics_labels() {
      return _reverse(_map(this.analytics.graph, 'label'))
    },
    options() {
      return {
        chart: {
          id: 'vuechart'
        },
        xaxis: {
          categories: this.analytics_labels
        },
        stroke: {
          curve: 'smooth'
        }
      }
    },
    series() {
      return [
        {
          name: 'Campaign',
          data: this.analytics_values
        }
      ]
    }
  },
  methods: {
    getAnalytics() {
      request.get(`api/leads/analytics`).then(({ data }) => {
        this.analytics = data
      })
    },
    getGroups() {
      request.get(`api/leads/groups?all=true`).then(({ data }) => {
        this.groups = data
      })
    },
    getLeads() {
      this.loadItems(
        `api/leads?search=${this.filters.search}&status=${this.filters.status}&group=${this.filters.group.id}`,
        true
      )
    },
    getMoreLeads() {
      this.loadMoreItems()
    },
    openImport(type) {
      if (type === 'excel') {
        this.open_import_via_excel = true
      } else if (type === 'clients') {
        this.open_import_via_clients = true
      } else if (type === 'webhooks') {
        this.open_connect_with_webhooks = true
      } else if (type === 'create') {
        this.activeLead = null
        this.open_lead_dialog = true
      } else if (type === 'create_group') {
        this.activeLead = null
        this.open_lead_group_dialog = true
      }
    },
    handleImportExcelSave() {
      this.getLeads()
      this.$nextTick(() => {
        this.open_import_via_excel = false
        this.getAnalytics()
      })
    },
    handleImportClientsSave() {
      this.getLeads()
      this.$nextTick(() => {
        this.open_import_via_clients = false
        this.getAnalytics()
      })
    },
    sendEmailCampaign() {
      this.send_email_campaign = true
    },
    handleSaveEmailCampaign(payload) {
      this.addItem(
        `api/lead-campaign/email`,
        payload,
        'Email campaign created',
        () => {
          this.send_email_campaign = false
        },
        null
      )
    },
    sendSmsCampaign() {
      this.send_sms_campaign = true
    },
    handleSaveSMSCampaign(payload) {
      this.addItem(
        `api/lead-campaign/sms`,
        payload,
        'SMS/MMS campaign created!',
        () => {
          this.send_sms_campaign = false
        },
        null
      )
    },
    phoneFormat(string) {
      if (!string || string === '') {
        return ''
      }
      return phoneFormatter.format(string, '(NNN) NNN-NNNN')
    }
  }
}
</script>

<style scoped lang="scss"></style>
