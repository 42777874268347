<template>
  <div>
    <CustomDialog
      :open.sync="open"
      :title="isEdit ? `Edit Email Campaign` : `Create Email Campaign`"
      @click-close="close_dialog"
      :max-width="mdAndDown ? `100%` : 1024"
      ref="dialog"
      fullscreen
    >
      <template v-slot:content>
        <v-card flat>
          <v-card-text class="py-0">
            <v-form v-model="valid">
              <v-row dense>
                <v-col md="9">
                  <v-stepper
                    v-model="progress"
                    light
                    class="border-color--grey border-1"
                  >
                    <v-stepper-header>
                      <v-stepper-step
                        :rules="[step1Rules]"
                        :complete="progress > 1"
                        :step="1"
                      >
                        Settings
                      </v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step
                        :rules="[step2Rules]"
                        :complete="progress > 2"
                        :step="2"
                      >
                        Scripts & Schedules
                      </v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :rules="[step3Rules]" :step="3">
                        Previews
                      </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                      <v-stepper-content :step="1" class="pa-1">
                        <v-card flat :min-height="400">
                          <v-card-text>
                            <v-text-field
                              filled
                              dense
                              clearable
                              clear-icon="mdi-close-circle-outline"
                              v-model="mapping.name"
                              label="Campaign Name (Required)"
                              hide-details="auto"
                              placeholder="Required"
                              :rules="requiredRules"
                              class="mb-2"
                            ></v-text-field>

                            <v-text-field
                              filled
                              dense
                              clearable
                              clear-icon="mdi-close-circle-outline"
                              v-model="mapping.subject"
                              label="Campaign Subject (Required)"
                              hide-details="auto"
                              placeholder="Required"
                              :rules="requiredRules"
                              class="mb-2"
                            ></v-text-field>
                            <v-row dense>
                              <v-col md="12" v-if="recipient_type === 'groups'">
                                <v-select
                                  dense
                                  filled
                                  :rules="requiredRules"
                                  clear-icon="mdi-close-circle-outline"
                                  label="Group Recipients (Optional)"
                                  placeholder="Optional"
                                  hide-details="auto"
                                  multiple
                                  small-chips
                                  :items="groups"
                                  item-text="name"
                                  item-value="id"
                                  deletable-chips
                                  chips
                                  clearable
                                  v-model="mapping.groups"
                                  class="mb-2"
                                ></v-select>
                              </v-col>
                              <v-col
                                md="12"
                                v-else-if="recipient_type === 'leads'"
                              >
                                <v-autocomplete
                                  v-model="mapping.leads"
                                  :items="search_items"
                                  :loading="isLoading"
                                  :search-input.sync="search_word"
                                  chips
                                  deletable-chips
                                  multiple
                                  clearable
                                  clear-icon="mdi-close-circle-outline"
                                  hide-details="auto"
                                  item-text="fullname"
                                  item-value="id"
                                  label="Search for leads (Required)"
                                  placeholder="Required"
                                  :rules="requiredRules"
                                  filled
                                  dense
                                  cache-items
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-title>
                                        Search for
                                        <strong>leads</strong>
                                      </v-list-item-title>
                                    </v-list-item>
                                  </template>
                                  <template
                                    v-slot:selection="{
                                      attr,
                                      on,
                                      item: item1,
                                      index,
                                      selected
                                    }"
                                  >
                                    <v-chip
                                      v-bind="attr"
                                      :input-value="selected"
                                      label
                                      small
                                      outlined
                                      clearable
                                      v-on="on"
                                      v-if="index < 2"
                                    >
                                      <span v-text="item1.fullname"></span>
                                    </v-chip>
                                    <v-chip
                                      v-bind="attr"
                                      :input-value="selected"
                                      label
                                      small
                                      outlined
                                      clearable
                                      v-on="on"
                                      v-if="index === 2"
                                    >
                                      <span>
                                        + {{ mapping.leads.length - 2 }} others
                                      </span>
                                    </v-chip>
                                  </template>
                                  <template v-slot:item="{ item: item2 }">
                                    <v-list-item-content :key="item2.id">
                                      <v-list-item-title
                                        v-text="item2.fullname"
                                      ></v-list-item-title>
                                      <v-list-item-subtitle
                                        v-text="
                                          item2.email ||
                                          item2.contacts.input ||
                                          ''
                                        "
                                      ></v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col md="6">
                                <v-text-field
                                  filled
                                  dense
                                  clearable
                                  clear-icon="mdi-close-circle-outline"
                                  v-model="mapping.from_email"
                                  label="Using From Email (Optional)"
                                  :rules="emailRules"
                                  hide-details="auto"
                                  class="mb-2"
                                  placeholder="Optional"
                                ></v-text-field>
                              </v-col>
                              <v-col md="6">
                                <v-text-field
                                  filled
                                  dense
                                  clearable
                                  :rules="multipleEmailRules"
                                  clear-icon="mdi-close-circle-outline"
                                  v-model="mapping.cc"
                                  label="Cc (Optional)"
                                  hide-details="auto"
                                  class="mb-2"
                                  placeholder="Optional, Separated by comma"
                                ></v-text-field>
                              </v-col>
                              <v-col md="6">
                                <v-text-field
                                  filled
                                  dense
                                  clearable
                                  clear-icon="mdi-close-circle-outline"
                                  v-model="mapping.reply_to"
                                  label="Reply To Email (Optional)"
                                  :rules="emailRules"
                                  hide-details="auto"
                                  class="mb-2"
                                  placeholder="Optional"
                                ></v-text-field>
                              </v-col>
                              <v-col md="6">
                                <v-autocomplete
                                  :items="signatures"
                                  item-text="name"
                                  item-value="id"
                                  filled
                                  dense
                                  deletable-chips
                                  chips
                                  small-chips
                                  clearable
                                  clear-icon="mdi-close-circle-outline"
                                  v-model="mapping.email_signature"
                                  label="Email Signature (Optional)"
                                  hide-details="auto"
                                  placeholder="Optional"
                                  append-outer-icon="mdi-plus-circle-outline"
                                  @click:append-outer="addSignature"
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-radio-group v-model="templating" column>
                              <span class="caption mb-1 text--secondary">
                                Campaign Type
                              </span>
                              <v-radio value="single_template">
                                <template v-slot:label>
                                  <p>
                                    Single template/content campaign
                                    <br />
                                    <small>
                                      Using one selected template or content
                                      throughout the campaign period
                                    </small>
                                  </p>
                                </template>
                              </v-radio>
                              <v-radio value="multiple_template">
                                <template v-slot:label>
                                  <p>
                                    Multiple template/content campaign
                                    <br />
                                    <small>
                                      Using different template or content
                                      throughout the campaign period
                                    </small>
                                  </p>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                      <v-stepper-content :step="2" class="pa-1">
                        <v-card flat :min-height="400">
                          <v-card-text>
                            <v-row dense>
                              <v-col md="5">
                                <v-text-field
                                  v-model="number_of_iterations"
                                  type="number"
                                  label="Number of iteration"
                                  dense
                                  @keydown="false"
                                  :rules="minMaxRules"
                                  hide-details="auto"
                                  min="1"
                                  max="20"
                                  persistent-hint
                                  hint="Min: 1, Max: 20"
                                  placeholder="Required. Value from 1 to 20"
                                  filled
                                  class="mb-2"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                md="7"
                                v-if="templating === 'single_template'"
                              >
                                <v-radio-group
                                  class="mx-auto text-center"
                                  v-model="content_type"
                                  row
                                  @change="
                                    updateGlobalContentType(content_type)
                                  "
                                  hide-details="auto"
                                  label="Content Type : "
                                >
                                  <v-radio
                                    label="Template"
                                    value="template"
                                  ></v-radio>
                                  <v-radio
                                    label="Plain Text"
                                    value="plain"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                              <v-col :md="12">
                                <v-select
                                  v-show="
                                    templating === 'single_template' &&
                                    content_type === 'template'
                                  "
                                  dense
                                  filled
                                  :rules="requiredRules"
                                  clear-icon="mdi-close-circle-outline"
                                  label="Select Template for All Iteration"
                                  placeholder="Required"
                                  hide-details="auto"
                                  small-chips
                                  :value="global_template"
                                  :items="templates"
                                  item-text="name"
                                  item-value="id"
                                  deletable-chips
                                  chips
                                  clearable
                                  v-model="global_template"
                                  class="mb-2"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title class="d-block">
                                          No available template yet!
                                        </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-actions>
                                        <v-btn
                                          @click.stop="createNewTemplate"
                                          outlined
                                          depressed
                                          class="text-none caption"
                                          text
                                        >
                                          Create new template
                                        </v-btn>
                                      </v-list-item-actions>
                                    </v-list-item>
                                  </template>
                                </v-select>
                                <v-textarea
                                  v-show="
                                    templating === 'single_template' &&
                                    content_type === 'plain'
                                  "
                                  clearable
                                  placeholder="Required, Enter text"
                                  label="Enter Email Contents for All Iteration"
                                  auto-grow
                                  :rows="3"
                                  @change="
                                    handleGLobalPlainText(global_content)
                                  "
                                  v-model="global_content"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                            <v-expansion-panels
                              v-model="panels"
                              multiple
                              flat
                              :key="counter"
                            >
                              <v-expansion-panel
                                v-for="(iteration, index) in iteration_items"
                                :key="index"
                                class="border-1 border-color--grey"
                              >
                                <v-expansion-panel-header class="subtitle-2">
                                  Iteration : {{ iteration.step }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-row dense>
                                    <v-col
                                      md="12"
                                      v-if="templating === 'multiple_template'"
                                    >
                                      <span class="subtitle-2 fullwidth">
                                        Content Type:
                                      </span>
                                      <v-radio-group
                                        v-model="iteration.content_type"
                                        row
                                        @change="
                                          updateIterationContentType(
                                            iteration.content_type,
                                            index
                                          )
                                        "
                                        hide-details="auto"
                                      >
                                        <v-radio
                                          label="Template"
                                          value="template"
                                        ></v-radio>
                                        <v-radio
                                          label="Plain Text"
                                          value="plain"
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                    <v-col
                                      md="12"
                                      v-if="
                                        templating === 'multiple_template' &&
                                        iteration.content_type === 'template'
                                      "
                                    >
                                      <span class="subtitle-2 fullwidth">
                                        Contents
                                      </span>
                                      select content type
                                      <v-select
                                        v-if="templating !== 'single_template'"
                                        dense
                                        filled
                                        :rules="requiredRules"
                                        clear-icon="mdi-close-circle-outline"
                                        placeholder="Required"
                                        hide-details="auto"
                                        small-chips
                                        :items="templates"
                                        item-text="name"
                                        item-value="id"
                                        deletable-chips
                                        chips
                                        clearable
                                        v-model="iteration.template"
                                        :label="`Select Template for Iteration ${
                                          index + 1
                                        }`"
                                        class="mb-2"
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-content>
                                              <v-list-item-title
                                                class="d-block"
                                              >
                                                No available template yet!
                                              </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-actions>
                                              <v-btn
                                                @click.stop="createNewTemplate"
                                                outlined
                                                depressed
                                                class="text-none caption"
                                                text
                                              >
                                                Create new template
                                              </v-btn>
                                            </v-list-item-actions>
                                          </v-list-item>
                                        </template>
                                      </v-select>
                                    </v-col>
                                    <v-col
                                      md="12"
                                      v-else-if="
                                        templating === 'multiple_template' &&
                                        iteration.content_type === 'plain'
                                      "
                                    >
                                      <v-textarea
                                        clearable
                                        placeholder="Required, Enter text"
                                        :label="`Enter Email Contents for Iteration ${
                                          index + 1
                                        }`"
                                        auto-grow
                                        :rows="3"
                                        v-model="iteration.content"
                                      ></v-textarea>
                                    </v-col>
                                    <v-col md="12">
                                      <span class="subtitle-2">Schedule</span>
                                      <span class="caption ml-2">
                                        (This iteration will be sent one time
                                        during this period)
                                      </span>

                                      <DateRangePicker
                                        ref="picker"
                                        class="mt-2 mx-auto"
                                        :min-date="today"
                                        opens="inline"
                                        :key="index"
                                        :date-range="iteration.schedule"
                                        :show-week-numbers="false"
                                        :ranges="false"
                                        :time-picker="false"
                                        :show-dropdowns="false"
                                        auto-apply
                                        @update="handleUpdate($event, index)"
                                      >
                                        <template
                                          v-slot:input="picker"
                                          style="min-width: 350px"
                                        >
                                          <template v-if="picker.startDate">
                                            Around
                                            <v-chip
                                              label
                                              class="mr-1 border-0"
                                              outlined
                                            >
                                              {{
                                                moment(picker.startDate).format(
                                                  'YYYY-MM-DD'
                                                )
                                              }}
                                            </v-chip>
                                            and
                                            <v-chip
                                              class="ml-1 border-0"
                                              outlined
                                              label
                                            >
                                              {{
                                                moment(picker.endDate).format(
                                                  'YYYY-MM-DD'
                                                )
                                              }}
                                            </v-chip>
                                          </template>
                                          <div v-else>
                                            Select start date and end date from
                                            below
                                          </div>
                                        </template>
                                      </DateRangePicker>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                      <v-stepper-content :step="3" class="pa-1">
                        <v-card flat :min-height="400">
                          <v-card-text>
                            <v-simple-table
                              class="fullwidth border-1 border-color--grey"
                            >
                              <template v-slot:default>
                                <tbody>
                                  <tr>
                                    <td class="pl-2">Campaign Name :</td>
                                    <td>
                                      {{
                                        mapping.name ||
                                        'Not set. This is a required field!'
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pl-2">Send to :</td>
                                    <td>
                                      {{ groups_in_text }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pl-2">
                                      Use email for sending :
                                    </td>
                                    <td>
                                      {{
                                        mapping.from_email ||
                                        'Using system default'
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pl-2">
                                      Use email for response notification :
                                    </td>
                                    <td>
                                      {{ mapping.email_response_to || 'None' }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pl-2">Use email for CC :</td>
                                    <td>{{ mapping.cc || 'None' }}</td>
                                  </tr>
                                  <tr>
                                    <td class="pl-2">Subject :</td>
                                    <td>
                                      {{
                                        mapping.subject ||
                                        'Not set. This is a required field!'
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pl-2">Number of Iteration :</td>
                                    <td>
                                      {{
                                        iteration_items.length ||
                                        'Empty. This is a required field!'
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                            <v-card
                              class="mt-1"
                              flat
                              v-if="payload.iterations.length"
                            >
                              <v-window v-model="preview_step">
                                <v-window-item
                                  class="border-1 border-color--grey rounded-10"
                                  :value="index"
                                  v-for="(item2, index) in payload.iterations"
                                  :key="index"
                                >
                                  <v-card-text>
                                    <h5 class="subtitle-1">
                                      Iteration : {{ index + 1 }}
                                    </h5>
                                    <p v-if="item2.content_type === 'template'">
                                      Template :
                                      {{
                                        item2.template
                                          ? templateById(item2.template)
                                          : 'No template selected!'
                                      }}
                                    </p>
                                    <p
                                      v-else-if="item2.content_type === 'plain'"
                                    >
                                      Message :
                                      {{
                                        item2.content ||
                                        'No message set!' | truncate
                                      }}
                                    </p>
                                    <p>
                                      Schedule:
                                      <v-chip outlined class="mr-1" label>
                                        {{
                                          item2.schedule
                                            ? item2.schedule.startDate ||
                                              'Not Set'
                                            : 'Not set'
                                        }}
                                      </v-chip>
                                      -
                                      <v-chip outlined class="ml-1" label>
                                        {{
                                          item2.schedule
                                            ? item2.schedule.endDate ||
                                              'Not Set'
                                            : 'Not set'
                                        }}
                                      </v-chip>
                                    </p>
                                  </v-card-text>
                                </v-window-item>
                              </v-window>
                              <v-card-actions
                                class="px-2"
                                v-if="payload.iterations.length > 1"
                              >
                                <v-btn
                                  text
                                  class="text-none"
                                  :disabled="preview_step === 0"
                                  @click="preview_step--"
                                  small
                                >
                                  Previous Iteration
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  class="text-none"
                                  :disabled="
                                    preview_step ===
                                    payload.iterations.length - 1
                                  "
                                  @click="preview_step++"
                                  small
                                >
                                  Next Iteration
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
                <v-col md="3">
                  <v-sheet
                    class="d-flex"
                    color="primary lighten-3"
                    height="100%"
                  >
                  </v-sheet>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:entire-actions>
        <v-row dense>
          <v-btn
            v-if="progress > 1"
            outlined
            text
            class="text-none"
            @click="progress--"
          >
            Back
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="progress < 3"
            outlined
            text
            class="text-none"
            :disabled="!can_next"
            @click="progress += 1"
          >
            Next
          </v-btn>
          <v-btn
            v-if="progress > 2"
            :loading="btnloading"
            text
            class="text-none"
            :disabled="!can_submit"
            @click="save"
            outlined
          >
            {{ isEdit ? 'Update' : 'Save' }}
          </v-btn>
        </v-row>
      </template>
    </CustomDialog>
    <DeleteDialog
      :open-dialog.sync="confirm_edit_campaign"
      title="Updating Campaign"
      text-content="Are you sure you want to update this campaign?<br> This will remove any previous iterations and pending emails generated for this campaign."
      @delete="proceedUpdate"
      delete-button-text="Yes! Update Campaign"
    ></DeleteDialog>
  </div>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import request from '@/services/axios_instance'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
import DeleteDialog from '@/common/DeleteDialog.vue'
import _debounce from 'lodash/debounce'

export default {
  name: 'EmailCampaignCreator',
  components: { DeleteDialog, CustomDialog, DateRangePicker },
  props: {
    dialog: { type: Boolean, default: false },
    targetType: { type: String, default: 'groups' },
    item: Object,
    templates: {
      type: Array,
      default: function () {
        return []
      }
    },
    signatures: {
      type: Array,
      default: function () {
        return []
      }
    },
    targets: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: () => ({
    confirm_edit_campaign: false,
    progress: 1,
    preview_step: 0,
    groups: [],
    open: false,
    counter: 0,
    mapping: {
      id: null,
      name: null,
      attachments: [], //id of attachment table
      subject: null,
      cc: null,
      from_email: null,
      reply_to: null,
      email_response_to: null,
      email_signature: null,
      groups: [], //id of lead_groups
      leads: [] //id of leads
    },
    content_type: 'template',
    templating: 'single_template',
    number_of_iterations: 1,
    global_template: null,
    global_content: null,
    valid: false,
    requiredRules: [(v) => !!v || 'This field is required'],
    fileRules: [(v) => (v && v.length < 6) || 'Max upload exceed 5 files'],
    emailRules: [(v) => !v || /.+@.+/.test(v) || 'Invalid Email address'],
    minMaxRules: [
      (v) => !!v || 'This field is required',
      (v) => (v && parseInt(v) > 0) || 'Min value is 1',
      (v) => (v && parseInt(v) < 21) || 'Max value is 20'
    ],
    placeholders: [
      'target_first_name',
      'target_last_name',
      'target_fullname',
      'target_organization',
      'target_email',
      'from_website',
      'from_organization'
    ],
    iteration_items: [],
    btnloading: false,
    panels: [],
    search_items: [],
    isLoading: false,
    search_word: null,
    recipient_type: 'groups'
  }),
  computed: {
    isEdit() {
      return !!this.item
    },
    today() {
      return this.moment(new Date()).format('YYYY-MM-DD')
    },
    can_proceed_2() {
      return this.step1Rules()
    },
    can_proceed_3() {
      return this.step2Rules()
    },
    can_submit() {
      return this.step3Rules()
    },
    can_next() {
      if (this.progress === 1) return this.can_proceed_2
      else if (this.progress === 2) return this.can_proceed_3
      else return false
    },
    payload() {
      return {
        type: this.templating,
        name: this.mapping.name,
        subject: this.mapping.subject,
        cc: this.mapping.cc,
        from_email: this.mapping.from_email,
        reply_to: this.mapping.reply_to,
        email_response_to: this.mapping.email_response_to,
        email_signature: this.mapping.email_signature,
        groups: this.mapping.groups,
        leads: this.mapping.leads,
        iterations: this.iteration_items.map((i) => {
          return {
            content_type: i.content_type,
            template:
              this.templating === 'single_template'
                ? this.global_template || null
                : i.template,
            content: i.content_type === 'template' ? null : i.content,
            schedule: {
              startDate: i.schedule.startDate,
              endDate: i.schedule.endDate
            }
          }
        })
      }
    },
    groups_in_text() {
      let selected = this.groups.filter((i) =>
        this.mapping.groups.includes(i.id)
      )
      return selected.length
        ? selected
            .map((j) => {
              return j.name
            })
            .join(',')
        : 'No groups selected'
    }
  },
  watch: {
    targetType: {
      handler: function (val) {
        this.recipient_type = val
      },
      immediate: true
    },
    dialog: {
      handler: function (val) {
        this.open = val
        this.btnloading = false
        if (val && this.recipient_type === 'groups') {
          this.getGroups()
        }
      },
      immediate: true
    },
    open(val) {
      this.$emit('update:dialog', val)
    },
    item: {
      handler: function (val) {
        if (val) {
          this.mapItem(val)
        }
      },
      immediate: true,
      deep: true
    },
    targets: {
      handler: function (val) {
        if (val.length > 0) {
          this.mapping.leads = val.map((i) => {
            return i.hasOwnProperty('id') ? i.id : i
          })
          this.search_items = val
        } else {
          this.mapping.leads = []
        }
      },
      deep: true,
      immediate: true
    },
    number_of_iterations: {
      handler: function (val) {
        if (val > 0 && val <= 20 && !this.isEdit) {
          this.iteration_items = []
          for (let i = 0; i < val; i++) {
            this.iteration_items.push({
              step: i + 1,
              content_type: 'template',
              content: null,
              template:
                this.templating === 'single_template'
                  ? this.global_template || null
                  : null,
              schedule: {
                startDate: null,
                endDate: null
              }
            })
          }
          this.$nextTick(this.open_all())
          this.counter++
        }
      },
      immediate: true
    },
    search_word: _debounce(function (val) {
      this.search(val)
    }, 500)
  },
  methods: {
    getGroups() {
      request.get(`api/leads/groups?all=true`).then(({ data }) => {
        this.groups = data
      })
    },
    close_dialog() {
      this.open = false
    },
    open_all() {
      this.panels = [...Array(this.iteration_items).keys()].map((k, i) => i)
    },
    save() {
      if (!this.isEdit) {
        this.btnloading = true
        this.$emit('save', this.payload)
      } else {
        this.confirm_edit_campaign = true
      }
    },
    search(keyword) {
      if (
        !this.isLoading &&
        keyword &&
        (keyword !== 'undefined' || typeof keyword === 'undefined')
      ) {
        this.isLoading = true
        request
          .get(`api/leads?search=${keyword}&per_page=10`)
          .then(({ data }) => {
            if (data.data) this.search_items = data.data
          })
          .finally(() => (this.isLoading = false))
      }
    },
    proceedUpdate() {
      this.btnloading = true
      this.confirm_edit_campaign = false
      this.$emit('update', this.payload)
    },
    mapItem(item) {
      this.recipient_type = item.target_type
      this.templating = item.props.templating
      this.mapping.id = item.id
      this.mapping.name = item.name
      this.mapping.attachments = []
      this.mapping.subject = item.props.subject
      this.mapping.cc = item.props.cc
      this.mapping.from_email = item.props.from_email
      this.mapping.reply_to = item.props.reply_to
      this.mapping.email_response_to = item.props.email_response_to || null
      this.mapping.email_signature = item.props.email_signature || null
      this.mapping.groups = item.groups.map((i) => {
        return i.id
      })
      this.mapping.leads = item.leads.map((i) => {
        return i.hasOwnProperty('id') ? i.id : i
      })
      this.search_items = item.leads
      this.number_of_iterations = item.iterations.length || 0
      this.global_template =
        item.props.templating === 'single_template'
          ? item.iterations[0].templatable_id
          : null
      this.global_content = null
      this.iteration_items = []
      for (let i = 0; i < this.number_of_iterations; i++) {
        this.iteration_items.push({
          step: i + 1,
          template: item.iterations[i].templatable_id,
          content_type: item.iterations[i].content_type,
          content: item.iterations[i].message,
          schedule: {
            startDate: moment(item.iterations[i].startDate).format(
              'YYYY-MM-DD'
            ),
            endDate: moment(item.iterations[i].endDate).format('YYYY-MM-DD')
          }
        })
      }
      this.$nextTick(this.open_all)
    },
    templateById(id) {
      if (!id) return 'Template not set!'
      let index = this.templates.findIndex((i) => i.id === id)
      return ~index ? this.templates[index].name : 'Template not found!'
    },
    step1Rules() {
      let is_recipient_ok =
        this.targetType === 'groups'
          ? this.mapping.groups && this.mapping.groups.length
          : this.mapping.leads && this.mapping.leads.length
      return !!(
        this.mapping.name &&
        this.mapping.name.length &&
        this.mapping.subject &&
        this.mapping.subject.length &&
        is_recipient_ok
      )
    },
    step2Rules() {
      if (!this.payload.iterations.length) return false
      for (let i = 0; i < this.payload.iterations.length; i++) {
        let iteration = this.payload.iterations[i]
        if (
          (!iteration.template && !iteration.content) ||
          !iteration.schedule.startDate ||
          !iteration.schedule.endDate
        )
          return false
      }
      return true
    },
    step3Rules() {
      return this.step1Rules() && this.step2Rules()
    },
    handleUpdate(event, index) {
      this.iteration_items[index].schedule = {
        startDate: this.moment(event.startDate).format('YYYY-MM-DD'),
        endDate: this.moment(event.endDate).format('YYYY-MM-DD')
      }
    },
    updateIterationContentType(type, index) {
      this.iteration_items[index].content_type = type
      if (type === 'template') {
        this.iteration_items[index].content = null
      } else {
        this.iteration_items[index].template = null
      }
    },
    updateGlobalContentType(type) {
      this.iteration_items.forEach((el) => {
        el.content_type = type
      })
    },
    handleGLobalPlainText(val) {
      this.iteration_items.forEach((el) => {
        el.content = val
        el.content_type = 'plain'
      })
    },
    addSignature() {
      this.$emit('add-signature')
    },
    createNewTemplate() {
      this.$router.push({ name: 'builders-email--template-campaign' })
    }
  }
}
</script>

<style scoped lang="scss">
.v-stepper {
  box-shadow: none !important;

  .v-stepper__header {
    box-shadow: none !important;
  }
}
</style>
