<template>
  <CustomDialog
    :open.sync="open"
    title="Import From Clients"
    @click-close="close_dialog"
    :max-width="mdAndDown ? `100%` : 900"
    ref="dialog"
    button2-text="Import"
    @button2="save"
    @button1="close_dialog"
    :main-btn-disabled="!hasNodeSelected"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text class="py-0">
          <v-row dense justify="center" align="stretch">
            <v-col :cols="mdAndUp ? 7 : 12">
              <v-list dense flat v-if="items.length">
                <v-subheader class="subtitle-2 primary--text">
                  Select from existing clients
                </v-subheader>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                      :disabled="form_disabled"
                      :indeterminate="someNodesSelected"
                      :input-value="allNodesSelected"
                      v-model="selectAll"
                      class="select-all"
                      label="Select All"
                    ></v-checkbox>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item-group v-model="selected_nodes" multiple>
                  <v-list-item
                    :disabled="form_disabled"
                    v-for="client in items"
                    :key="client.id"
                    :value="client"
                  >
                    <template v-slot:default="{ active, toggle }">
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          :true-value="client.id"
                          @click="toggle"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ client.fullname }} |
                          <span class="caption">{{ client.email }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="client.telephone">
                          {{ client.telephone.formatInternational }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <empty v-else headline="No clients yet" />
            </v-col>
            <v-col :cols="mdAndUp ? 5 : 12">
              <v-list dense subheader flat>
                <v-subheader class="subtitle-2 priamry--text">
                  Import Settings
                </v-subheader>
                <v-list-item>
                  <v-list-item-content>
                    <label class="subtitle-2 primary--text"> Status </label>
                    <v-radio-group
                      row
                      v-model="import_settings.status"
                      hide-details="auto"
                      :disabled="form_disabled"
                    >
                      <v-radio label="Active" value="active"></v-radio>
                      <v-radio label="Inactive" value="inactive"></v-radio>
                    </v-radio-group>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <label class="subtitle-2 primary--text">
                      Attach To Groups (Optional)
                    </label>
                    <v-select
                      item-value="id"
                      item-text="name"
                      v-model="import_settings.groups"
                      :disabled="form_disabled"
                      :items="current_groups"
                      multiple
                      deletable-chips
                      chips
                      dense
                      small-chips
                      hide-details="auto"
                      outlined
                      placeholder="Select or create group"
                    >
                      <template v-slot:append-outer>
                        <v-menu bottom left :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-text-field
                                v-model="new_group"
                                label="Group name"
                                hide-details
                                append-outer-icon="mdi-content-save-move-outline"
                                filled
                                dense
                                @click:append-outer="saveNewGroup"
                              >
                              </v-text-field>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-select>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="primary--text subtitle-2">
                      Selected Clients : {{ selected_nodes.length }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-avatar tile size="300">
                <v-img
                  contain
                  :src="require('@/assets/svgs/images/overviews.svg').default"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import _chunks from 'lodash/chunk'
import request from '@/services/axios_instance'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ImportClients',
  components: {
    CustomDialog
  },
  mixins: [fetch_services],
  props: {
    dialog: { type: Boolean, default: false },
    groups: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: () => ({
    new_group: null,
    current_groups: [],
    open: false,
    form_disabled: false,
    selectAll: false,
    selected_nodes: [],
    max_row_per_post: 20,
    import_settings: {
      status: 'active',
      groups: []
    },
    statuses: ['active', 'inactive']
  }),
  created() {
    this.fetchClients()
  },
  watch: {
    dialog(val) {
      this.open = val
    },
    open(val) {
      this.$emit('update:dialog', val)
    },
    selectAll(val) {
      this.selectAllItems(val)
    },
    groups: {
      handler: function (val) {
        this.current_groups = _cloneDeep(val)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    noNodesSelected() {
      return this.selected_nodes.length === 0
    },
    someNodesSelected() {
      return this.selected_nodes.length > 0 && !this.allNodesSelected
    },
    allNodesSelected() {
      return this.selected_nodes.length === this.items.length
    },
    hasNodeSelected() {
      return this.selected_nodes.length > 0
    },
    json_rows() {
      let jsons = []
      for (let step = 0; step < this.selected_nodes.length; step++) {
        let row = this.mapRow(this.selected_nodes[step])
        if (row) {
          jsons.push(row)
        }
      }
      return jsons
    }
  },
  methods: {
    close_dialog() {
      this.open = false
    },
    fetchClients() {
      this.loadItems(`api/clients?all=true`, false)
    },
    selectAllItems(checked) {
      this.selected_nodes = []
      if (checked) {
        for (let index in this.items) {
          this.selected_nodes.push(this.items[index])
        }
      }
    },
    mapRow(row) {
      if (!row || typeof row === 'undefined') return false
      return {
        first_name: row.first_name,
        last_name: row.last_name || null,
        email: row.email,
        contact_number: { input: row.telephone ? row.telephone.e164 : null },
        interests: null,
        organization: row.company ? row.company.name : null,
        address: row.props.location ? row.props.location : null
      }
    },
    async save() {
      this.form_disabled = true
      let chunks = _chunks(this.json_rows, this.max_row_per_post)
      for (let i = 0; i < chunks.length; i++) {
        let { data } = await request.post(`api/leads/import/clients`, {
          leads: chunks[i],
          status: this.import_settings.status,
          groups: this.import_settings.groups
        })
        this.skipped = this.skipped + parseInt(data.skipped)
        this.created = this.created + parseInt(data.created)
        this.updated = this.updated + parseInt(data.updated)
        // console.log(this.created, this.updated, this.skipped)
      }
      this.$nextTick(() => {
        if (this.created > 0 || this.updated > 0) {
          this.appSnackbar(
            `Importing leads success! Created : ${this.created}, Updated: ${this.updated}, Skipped: ${this.skipped}`,
            'success'
          )
        }
        if (this.skipped > 0 && this.created === 0 && this.updated === 0) {
          this.appSnackbar(
            `Importing leads failed! ${this.skipped} items skipped`,
            'error'
          )
        }
        this.$emit('save', true)
        this.$event.$emit('btnloading_off')
        this.form_disabled = false
      })
    },
    saveNewGroup() {
      if (!this.new_group || this.new_group.trim().length < 1) {
        this.appSnackbar('Group name is required!', 'error')
      } else {
        request
          .post(`api/leads/groups`, { name: this.new_group })
          .then(({ data }) => {
            this.new_group = null
            this.current_groups.unshift(data)
            this.$emit('new-group', data)
            this.appSnackbar('Group added!', 'success')
          })
      }
    }
  }
}
</script>

<style lang="css">
.select-all
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input {
  margin-right: 30px !important;
}
</style>
