<template>
  <CustomDialog
    :open.sync="open"
    title="Import From Excel"
    @click-close="close_dialog"
    :max-width="mdAndDown ? `100%` : 900"
    ref="dialog"
    :button2-text="save_text"
    @button2="save"
    @button1="close_dialog"
    :main-btn-disabled="
      !mapping.email || !file || has_valid_emails.length === 0
    "
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text class="py-0">
          <input
            class="hidden"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="handleChange"
            ref="excel_upload"
            type="file"
          />
          <empty
            v-if="!file"
            headline="Upload Excel File"
            icon="mdi-file-excel"
          >
            <template v-slot:extra>
              <v-btn
                large
                depressed
                color="primary"
                @click="$refs.excel_upload.click()"
                class="text-none"
              >
                <v-icon small left>mdi-cloud-upload</v-icon>
                Upload File
              </v-btn>
            </template>
          </empty>
          <v-row v-if="rows.length">
            <v-col md="7" sm="12">
              <h2 class="title">Map columns</h2>
              <v-row dense>
                <v-col md="6" align-self="center">
                  <h6 align="left">
                    Select column for First Name / Full Name :
                  </h6>
                </v-col>
                <v-col md="6">
                  <v-select
                    :disabled="form_disabled"
                    dense
                    v-model="mapping.first_name"
                    :items="map_headers"
                    filled
                    hide-details
                    single-line
                    placeholder="Optional"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="6" align-self="center">
                  <h6 align="left">Select column for Last Name :</h6>
                </v-col>
                <v-col md="6">
                  <v-select
                    :disabled="form_disabled"
                    dense
                    v-model="mapping.last_name"
                    :items="map_headers"
                    filled
                    hide-details
                    single-line
                    placeholder="Optional"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="6" align-self="center">
                  <h6 align="left">
                    Select column for Email <sup class="text-red">*</sup>:
                  </h6>
                </v-col>
                <v-col md="6">
                  <v-select
                    required
                    dense
                    :disabled="form_disabled"
                    v-model="mapping.email"
                    :items="map_headers"
                    filled
                    hide-details
                    single-line
                    placeholder="Required"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="6" align-self="center">
                  <h6>Select column for Interests/Tags :</h6>
                </v-col>
                <v-col md="6">
                  <v-select
                    :disabled="form_disabled"
                    dense
                    v-model="mapping.interests"
                    :items="map_headers"
                    filled
                    hide-details
                    single-line
                    placeholder="Optional"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="6" align-self="center">
                  <h6 align="left">Select column for Organization :</h6>
                </v-col>
                <v-col md="6">
                  <v-select
                    :disabled="form_disabled"
                    dense
                    v-model="mapping.organization"
                    :items="map_headers"
                    filled
                    hide-details
                    single-line
                    placeholder="Optional"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="6" align-self="center">
                  <h6 align="left">Select column for Address :</h6>
                </v-col>
                <v-col md="6">
                  <v-select
                    :disabled="form_disabled"
                    dense
                    v-model="mapping.address"
                    :items="map_headers"
                    filled
                    hide-details
                    single-line
                    placeholder="Optional"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="6" align-self="center">
                  <h6 align="left">Select column for Country Code :</h6>
                </v-col>
                <v-col md="6">
                  <v-select
                    :disabled="form_disabled"
                    dense
                    v-model="mapping.country_code"
                    :items="map_headers"
                    filled
                    hide-details
                    single-line
                    placeholder="Optional"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-select>
                </v-col>
                <v-col md="12" class="caption">
                  For better country code storing, Values should be on
                  <a
                    href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
                    target="_blank"
                    >ISO Alpha-2</a>
                  codes ("US","CA",...) or
                  <a href="https://tools.ietf.org/html/rfc4646" target="_blank">RFC 4646</a>
                  codes ("en_US", "en_AE",...)
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="6" align-self="center">
                  <h6 align="left">Select column for Contact Number:</h6>
                </v-col>
                <v-col md="6">
                  <v-select
                    :disabled="form_disabled"
                    dense
                    v-model="mapping.contact_number"
                    :items="map_headers"
                    filled
                    hide-details
                    single-line
                    placeholder="Optional"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  ></v-select>
                </v-col>
                <v-col md="12" class="caption">
                  For better contact number storing, format your column values
                  to
                  <a href="https://en.wikipedia.org/wiki/E.164" target="_blank">
                    E.164
                  </a>
                  formats
                </v-col>
              </v-row>
            </v-col>
            <!--            <v-divider vertical v-if="mdAndUp"/>-->
            <v-col>
              <v-list subheader dense flat>
                <v-subheader class="title">Import Settings</v-subheader>
                <v-list-item>
                  <v-btn
                    block
                    large
                    :disabled="form_disabled"
                    outlined
                    @click="$refs.excel_upload.click()"
                    text
                    class="text-none"
                  >
                    <v-icon small left>mdi-cloud-upload</v-icon>
                    Upload New File
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-select
                      dense
                      hide-details
                      :disabled="form_disabled"
                      :items="statuses"
                      label="Status (Optional)"
                      placeholder="Select status"
                      v-model="import_settings.status"
                      filled
                    ></v-select>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-select
                      item-value="id"
                      item-text="name"
                      v-model="import_settings.groups"
                      :disabled="form_disabled"
                      :items="current_groups"
                      multiple
                      deletable-chips
                      chips
                      small-chips
                      label="Groups (Optional)"
                      hide-details
                      filled
                      placeholder="Select or create group"
                    >
                      <template v-slot:append-outer>
                        <v-menu bottom left :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-text-field
                                v-model="new_group"
                                label="Group name"
                                hide-details
                                append-outer-icon="mdi-content-save-move-outline"
                                filled
                                dense
                                @click:append-outer="saveNewGroup"
                              >
                              </v-text-field>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-select>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <p>
                      Note: First row of the file will be skipped and will be
                      considered as column headers.
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </v-list>
              <v-sheet color="grey lighten-3" class="pa-2">
                <p>Total Leads From File : ~{{ rows.length || 0 }}</p>
                <p>
                  Leads with Valid Email: {{ has_valid_emails.length || 0 }}
                </p>
                <p>
                  Leads with Invalid/No Email:
                  {{ rows.length - has_valid_emails.length || 0 }}
                </p>
              </v-sheet>
            </v-col>
          </v-row>
          <Empty
            v-if="file && json_rows.length === 0"
            headline="No valid entries!"
          ></Empty>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import readXlsxFile from 'read-excel-file'
import _tail from 'lodash/tail'
import _chunks from 'lodash/chunk'
import _cloneDeep from 'lodash/cloneDeep'
import request from '@/services/axios_instance'

export default {
  name: 'ImportExcel',
  components: { CustomDialog },
  props: {
    dialog: { type: Boolean, default: false },
    groups: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: () => ({
    current_groups: [],
    skipped: 0,
    updated: 0,
    created: 0,
    open: false,
    file: null,
    btnloading: false,
    rows: [],
    headers: [],
    import_settings: {
      status: 'active',
      groups: []
    },
    mapping: {
      first_name: null,
      last_name: null,
      email: null,
      contact_number: null,
      organization: null,
      address: null,
      interests: null,
      country_code: null
    },
    max_row_per_post: 10, //
    form_disabled: false,
    statuses: ['active', 'inactive'],
    new_group: null,
    is_csv: false
  }),
  computed: {
    save_text() {
      if (this.json_rows.length > 0) return `Import (${this.json_rows.length})`
      return `Import`
    },
    json_rows() {
      let jsons = []
      for (let step = 0; step < this.rows.length; step++) {
        let row = this.mapRow(_cloneDeep(this.rows[step]))
        if (row !== false) {
          jsons.push(row)
        }
      }
      return jsons
    },
    has_valid_emails() {
      return this.json_rows
        ? this.json_rows.filter((item) => /.+@.+/.test(item.email))
        : []
    },
    map_headers() {
      let array = []
      let clone = _cloneDeep(this.headers)
      for (let i = 0; i < clone.length; i++) {
        if (clone[i] === '' || !clone[i]) {
          array.push(`Untitled Column ${i + 1}`)
        } else {
          if (typeof clone[i] !== 'string') {
            continue
          }
          array.push(clone[i])
        }
      }
      return array
    }
  },
  watch: {
    dialog(val) {
      this.open = val
      if (val === true) this.resetData()
    },
    open(val) {
      this.$emit('update:dialog', val)
    },
    groups: {
      handler: function (val) {
        this.current_groups = _cloneDeep(val)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    resetData() {
      this.form_disabled = false
      this.file = null
      this.btnloading = false
      this.rows = this.headers = []
      this.mapping = {
        first_name: null,
        last_name: null,
        email: null,
        contact_number: null,
        organization: null,
        address: null,
        interests: null,
        country_code: null
      }
    },
    close_dialog() {
      this.open = false
    },
    getHIndex(header) {
      if (typeof header === 'undefined' || !header) return false
      if (!this.headers || this.headers.length < 0) return false
      if (header.includes('Untitled Column')) {
        let xpld = header.split(' ')
        if (xpld.length > 2) return parseInt(xpld[2]) - 1
        else return -1
      }
      let index = this.headers.findIndex(
        (i) => i.toString() === header.toString()
      )
      if (~index) return index
      return false
    },
    handleCsvFile(file) {
      const _this = this
      this.$papa.parse(file, {
        fastMode: true,
        header: false,
        skipEmptyLines: true,
        step: function (results, parser) {
          if (
            results.hasOwnProperty('data') &&
            !results.data.hasOwnProperty('undefined')
          ) {
            _this.rows.push(results.data)
          }
        },
        complete: function (results, file) {
          _this.headers = _this.rows[0]
          _this.rows = _tail(_this.rows)
        },
        error: function (error, file) {
          _this.appSnackbar(error, 'error')
        }
      })
    },
    handleChange(e) {
      this.file = e.target.files[0]
      if (this.file.name.includes('.csv')) {
        this.is_csv = true
        this.handleCsvFile(this.file)
      } else {
        this.is_csv = false
        readXlsxFile(this.file).then((rows) => {
          if (rows.length) {
            this.headers = rows[0]
            this.rows = _tail(rows)
          }
        })
      }
    },
    mapRow(row) {
      if (!row || typeof row === 'undefined') return false
      return {
        first_name:
          this.getHIndex(this.mapping.first_name) !== false
            ? row[this.getHIndex(this.mapping.first_name)]
            : null,
        last_name:
          this.getHIndex(this.mapping.last_name) !== false
            ? row[this.getHIndex(this.mapping.last_name)]
            : null,
        email:
          this.getHIndex(this.mapping.email) !== false
            ? row[this.getHIndex(this.mapping.email)]
            : null,
        contact_number:
          this.getHIndex(this.mapping.contact_number) !== false
            ? row[this.getHIndex(this.mapping.contact_number)]
            : null,
        interests:
          this.getHIndex(this.mapping.interests) !== false
            ? row[this.getHIndex(this.mapping.interests)]
            : null,
        organization: this.getHIndex(this.mapping.organization)
          ? row[this.getHIndex(this.mapping.organization)]
          : null,
        address:
          this.getHIndex(this.mapping.address) !== false
            ? row[this.getHIndex(this.mapping.address)]
            : null,
        country_code:
          this.getHIndex(this.mapping.country_code) !== false
            ? row[this.getHIndex(this.mapping.country_code)]
            : null
      }
    },
    async save() {
      this.form_disabled = true
      let chunks = _chunks(this.json_rows, this.max_row_per_post)
      for (let i = 0; i < chunks.length; i++) {
        let { data } = await request.post(`api/leads/import/excel`, {
          leads: chunks[i],
          groups: this.import_settings.groups,
          status: this.import_settings.status
        })
        this.skipped = this.skipped + parseInt(data.skipped)
        this.created = this.created + parseInt(data.created)
        this.updated = this.updated + parseInt(data.updated)
        // console.log(this.created, this.updated, this.skipped);
      }
      this.$nextTick(() => {
        if (this.created > 0 || this.updated > 0) {
          this.appSnackbar(
            `Importing leads success! Created : ${this.created}, Updated: ${this.updated}, Skipped: ${this.skipped}`,
            'success'
          )
        }
        if (this.skipped > 0 && this.created === 0 && this.updated === 0) {
          this.appSnackbar(
            `Importing leads failed! ${this.skipped} items skipped`,
            'error'
          )
        }
        this.$emit('save', true)
        this.$event.$emit('btnloading_off')
        this.form_disabled = false
      })
    },
    saveNewGroup() {
      if (!this.new_group || this.new_group.trim().length < 1) {
        this.appSnackbar('Group name is required!', 'error')
      } else {
        request
          .post(`api/leads/groups`, { name: this.new_group })
          .then(({ data }) => {
            this.new_group = null
            this.current_groups.unshift(data)
            this.$emit('new-group', data)
            this.appSnackbar('Group added!', 'success')
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hidden {
  display: none !important;
}

.text-red {
  color: red;
}
</style>
